const homeSwiper = new Swiper('.home-slider', {
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  speed: 2000,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  },
});



const pickupSwiper = new Swiper('.pickup-slider', {
  slidesPerView: 'auto',
  centeredSlides: false,
  breakpoints: {
    992: {
      //slidesPerView: 4,
      slidesPerGroup: 4,
    },
  },
  pagination: {
    el: '.swiper-border-pagination',
    clickable: true,
    bulletClass: 'bullet',
    bulletActiveClass: 'active',
    //renderBullet: function(index, className) {
    //  return '<span class="' + className + '"></span>';
    //},
  },
  navigation: {
    nextEl: '.swiper-btn-next',
    prevEl: '.swiper-btn-prev',
  },
});
